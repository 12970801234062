import { Dispatch, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { useFavoritedPeers, useMyConnections } from '../../hooks/usePeers';
import { SchedulingModal } from '../../components/scheduling-modal/scheduling-modal';
import {
  ConnectNowPeerCard,
  ConnectNowPeerCardSkeleton,
} from '../../components/mwa-3.5-redesign/connect-now-peer-card';
import { ROUTE_PATH } from '../../routes/route-paths';

const MyConnectionsPage = () => {
  const navigate = useHistory();
  const [scheduleModal, setScheduleModal] = useState<number>();
  const [scheduleAvailableNow, setScheduleAvailableNow] = useState(false);

  return (
    <>
      <div className="bg-neutral-700 bg-opacity-5 min-h-screen flex flex-col">
        <MobileMenuHeader onBack={() => navigate.goBack()} />
        <DesktopMenuHeader />
        <Body setScheduleModal={setScheduleModal} setScheduleAvailableNow={setScheduleAvailableNow} />
      </div>
      {scheduleModal && (
        <SchedulingModal
          open={!!scheduleModal}
          listenerAvailableNow={scheduleAvailableNow}
          onExit={(context) => {
            if (context === 'scheduled') {
              navigate.push(ROUTE_PATH.HOME);
            }
            setScheduleModal(undefined);
          }}
          listenerId={scheduleModal}
        />
      )}
    </>
  );
};

const Body: React.FC<{
  setScheduleModal: Dispatch<React.SetStateAction<number | undefined>>;
  setScheduleAvailableNow: Dispatch<React.SetStateAction<boolean>>;
}> = ({ setScheduleModal, setScheduleAvailableNow }) => {
  const favoritedPeers = useFavoritedPeers();
  const myConnections = useMyConnections();

  const hasFavorites = Boolean(favoritedPeers?.data?.count && favoritedPeers.data.count > 0);
  const hasConnections = Boolean(myConnections?.data?.count && myConnections.data.count > 0);

  const [visibleFavorites, setVisibleFavorites] = useState(9);
  const [visibleConnections, setVisibleConnections] = useState(9);

  const handleShowMore = (type: 'favorites' | 'connections') => {
    if (type === 'favorites') {
      setVisibleFavorites((prevCount) => prevCount + 9);
    } else if (type === 'connections') {
      setVisibleConnections((prevCount) => prevCount + 9);
    }
  };

  return (
    <div className="w-full space-y-3 md:space-y-6 md:max-w-7xl md:mx-auto md:px-6 md:pb-10 ">
      <div>
        <div className="flex flex-col px-3 md:px-0 pt-2 gap-4">
          <div>
            <div className="text-2xl font-textaBlack leading-normal">Favorites</div>
            <div className="text-sm font-thin opacity-70 font-texta leading-normal">
              Your favorite Peers{!hasFavorites && ' — press "Follow" to favorite a peer!'}
            </div>
          </div>
          {hasFavorites && (
            <div className="flex flex-col">
              <div className="flex flex-col md:flex-row flex-wrap gap-3 mx-auto md:mx-0">
                {favoritedPeers.isLoading && (
                  <>
                    <ConnectNowPeerCardSkeleton /> <ConnectNowPeerCardSkeleton />
                  </>
                )}
                {favoritedPeers?.data?.data
                  ?.slice(0, visibleFavorites)
                  .map((peer) => (
                    <ConnectNowPeerCard
                      key={peer.listener_role_id}
                      peer={peer}
                      setScheduleModal={setScheduleModal}
                      setScheduleAvailableNow={setScheduleAvailableNow}
                    />
                  ))}
              </div>
              {favoritedPeers?.data?.count && visibleFavorites < favoritedPeers.data.count && (
                <div
                  className="flex justify-end pt-3 pr-30 text-xl font-medium font-textaMedium antialiased leading-normal cursor-pointer"
                  onClick={() => handleShowMore('favorites')}
                >
                  Show More »
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="flex flex-col px-3 md:px-0 pt-2 gap-4">
          <div>
            <div className="text-2xl font-textaBlack leading-normal">My Connections</div>
            <div className="text-sm font-thin opacity-70 font-texta leading-normal">
              Peers you've interacted with{' '}
              {!hasConnections && `— Peers you've listened to or scheduled calls with will appear here.`}
            </div>
          </div>
          {hasConnections && (
            <div className="flex flex-col">
              <div className="flex flex-col md:flex-row flex-wrap gap-3 mx-auto md:mx-0">
                {myConnections.isLoading && (
                  <>
                    <ConnectNowPeerCardSkeleton /> <ConnectNowPeerCardSkeleton />
                  </>
                )}
                {myConnections?.data?.data
                  ?.slice(0, visibleConnections)
                  .map((peer) => (
                    <ConnectNowPeerCard
                      key={peer.listener_role_id}
                      peer={peer}
                      setScheduleModal={setScheduleModal}
                      setScheduleAvailableNow={setScheduleAvailableNow}
                    />
                  ))}
              </div>
              {myConnections?.data?.count && visibleConnections < myConnections.data.count && (
                <div
                  className="flex justify-end pt-3 pr-30 text-xl font-medium font-textaMedium antialiased leading-normal cursor-pointer"
                  onClick={() => handleShowMore('connections')}
                >
                  Show More »
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyConnectionsPage;
