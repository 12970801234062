import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ROUTE_PATH } from '../../routes/route-paths';

import { useActiveCalls, useCallRequests } from '../../hooks/useCalls';
import { useTagGroups } from '../../hooks/useTagGroups';
import { useRecommendedResourcesTextSearch, useResources } from '../../hooks/useResources';
import { useAvailableListeners } from '../../hooks/usePeers';
import { SearchQueries, useUser } from '../../hooks/useUser';
import { useRecommendedExperiencesTextSearch, SearchFilterOptions, Experience } from '../../hooks/useExperiences';
import { useProviderPlan } from '../../hooks/useExternalProviderPlans';
import { usePostCalls } from '../../hooks/usePostCall';

import { FilterMenuModal, TagFilters } from '../../components/filter/FomSelectionFilter';
import { CALL_UNITS_CALL_DEFAULT_LENGTH, SchedulingModal } from '../../components/scheduling-modal/scheduling-modal';
import ReflectionsModal from '../../components/reflections-modal/reflections-modal';
import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';

import { SearchInput } from '../../components/search/search-input';
import { SearchFiltersModal } from '../../components/search/search-filters-modal';
import { useReflections } from '../../hooks/useReflection';
import CounselingAndCare from './home-components/counseling-and-care';
import MyPrograms from './home-components/my-programs';
import ConnectNow from './home-components/connect-now';
import WelcomeVideo from './home-components/welcome-video';
import ResourceRecommendations from './home-components/resource-recommendations';
import ExperienceRecommendations from './home-components/experience-recommendations';
import CallConnections from './home-components/call-connections';
import moment from 'moment';
import MyPlan from './home-components/my-plan';
import { TopSearches } from './home-components/top-searches';

export interface ScheduleModalData {
  isNow: boolean;
  listenerId: number;
}

const getCachedQuery = (searchQueries: SearchQueries[] | null): string | undefined => {
  const storedFilters = localStorage.getItem('searchForSupportFilters');
  const parsedFilters: SearchFilterOptions | undefined = storedFilters ? JSON.parse(storedFilters) : undefined;
  if (parsedFilters?.createdAt && moment.utc(parsedFilters.createdAt).isAfter(moment().subtract(6, 'h'))) {
    // localStorage cache is valid, so use it
    return parsedFilters.supportQuery;
  } else if (searchQueries?.[0] && moment.utc(searchQueries[0].created_at).isAfter(moment().subtract(6, 'h'))) {
    // server cache is valid, so use it
    return searchQueries[0].query;
  } else {
    // cache results are invalid, so set the search filters to defaults
    return undefined;
  }
};

export const HomePage = () => {
  const { data: user, updateTagPreferences } = useUser();
  const history = useHistory();

  const [isVideo, setIsVideo] = useState(true);
  const [showTopSearches, setShowTopSearces] = useState(true);

  const { search: urlQuery } = useLocation();
  const { data: groupLevelResources, isFetching: groupLevelResourcesFetching } = useResources(user?.client_id);
  const { providerPlan, isFetching: providerPlansFetching } = useProviderPlan();

  const deepLinkSearch = new URLSearchParams(urlQuery).get('search');
  const [schedulingData, setScheduleModalData] = useState<ScheduleModalData>();
  const [searchFilterOpen, setSearchFilterOpen] = useState(false);
  const defaultSearchFilters = {
    supportQuery: '',
    limit: 8,
    page: 1,
    peerType: ['peerlistener', 'peer'],
  };
  const [searchFilters, setSearchFilters] = useState<SearchFilterOptions>(defaultSearchFilters);

  const experiences = useRecommendedExperiencesTextSearch({
    enabled: searchFilters.supportQuery !== undefined && searchFilters.supportQuery.trim() !== '',
    searchFilterOptions: searchFilters,
  });

  const recommendedResources = useRecommendedResourcesTextSearch({
    limit: 100,
    query: searchFilters.supportQuery,
    enabled: searchFilters.supportQuery !== undefined && searchFilters.supportQuery.trim() !== '',
  });

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [showReflectionModal, setShowReflectionModal] = useState(false);

  const { data: fomHierarchy } = useTagGroups();
  const { data: callRequests } = useCallRequests(user?.caller_role_id);
  const { data: incompletePostCalls } = usePostCalls(user?.caller_role_id, true);
  const { data: activeCalls } = useActiveCalls(user?.caller_role_id);

  const availableListeners = useAvailableListeners();
  const searchQueries = user?.search_queries?.length ? user?.search_queries : null;
  const currentReflection = useReflections();

  const handleFilterChange = ({ tag_group_ids, tag_ids }: TagFilters) => {
    updateTagPreferences.mutate({
      tag_group_ids,
      tag_ids,
    });
    setShowFilterMenu(false);
  };

  const handleReflectionModalDismiss = () => {
    setShowReflectionModal(false);
  };

  const handleScheduleModalOpen = ({ is_available: isNow, listener_role_id: listenerId }: Experience) => {
    setScheduleModalData({ isNow, listenerId });
  };

  const handleCloseScheduleModal = (callback?: () => {}) => {
    setScheduleModalData(undefined);
    callback?.();
  };

  const client = user?.caller_role.active_subscription?.package?.client;
  const isUnlimited = user?.onUnlimitedPlan;
  // if we're on an unlimited plan we don't have any minutes
  const availableMinutes = isUnlimited
    ? undefined
    : user?.caller_role.is_call_units
      ? user?.caller_role.payment_data.available_minutes / CALL_UNITS_CALL_DEFAULT_LENGTH
      : user?.caller_role.payment_data.available_minutes;

  const myPlanImage = user?.caller_role.active_subscription?.package?.my_plan_file_url;

  useEffect(() => {
    if (!user) {
      return;
    }
    const videoDisplay = localStorage.getItem('isVideoDisplay');
    setIsVideo(videoDisplay === 'true' || videoDisplay === null);
    const hideTopSearchClickAt = localStorage.getItem('hideTopSearchClickAt');
    // show top searches if they haven't been hidden by user in last 6hours
    setShowTopSearces(!hideTopSearchClickAt || moment().isAfter(moment(hideTopSearchClickAt).add(6, 'hours')));
  }, [user]);

  useEffect(() => {
    if (deepLinkSearch) {
      setSearchFilters((prevFilters) => ({
        ...prevFilters,
        supportQuery: deepLinkSearch,
      }));
      return;
    }

    const cachedQuery = getCachedQuery(searchQueries);
    if (cachedQuery) {
      setSearchFilters((prevFilters) => ({
        ...prevFilters,
        supportQuery: cachedQuery,
      }));
    } else {
      setSearchFilters((prevFilters) => ({
        ...prevFilters,
        supportQuery: cachedQuery,
      }));
    }
  }, [deepLinkSearch, searchQueries]);

  // go back to the top of the screen when you land on the page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const search = (text?: string, filters?: SearchFilterOptions) => {
    if (text && text.trim() === '') {
      return;
    }
    setSearchFilters((prev) => ({
      ...prev,
      ...filters,
      supportQuery: text || prev.supportQuery,
    }));
    const storedFilters = localStorage.getItem('searchForSupportFilters');
    const parsedFilters = storedFilters ? JSON.parse(storedFilters) : undefined;
    localStorage.setItem(
      'searchForSupportFilters',
      JSON.stringify({
        ...parsedFilters,
        supportQuery: text,
        createdAt: moment().toISOString(),
      }),
    );
  };

  return (
    <div className={`w-screen min-h-screen ${showFilterMenu ? 'overflow-hidden' : ''}`}>
      <MobileMenuHeader
        onConnect={() => history.push(ROUTE_PATH.AVAILABLE_LISTENERS)}
        availableMinutes={availableMinutes}
      />
      <DesktopMenuHeader />
      <div className="w-full mx-auto">
        <div className="bg-[#005eff] bg-opacity-10">
          <div className="flex flex-col items-center max-w-7xl w-full mx-auto py-4 px-4 md:px-6 min-h-[147px]">
            <div
              className={`pt-4 flex flex-nowrap w-full ${searchFilters.supportQuery && searchFilters.supportQuery !== '' && 'pb-4 mb-4'}' `}
            >
              <SearchInput
                query={searchFilters.supportQuery}
                search={(text) => search(text)}
                isLoading={experiences.isFetching}
                searchFilterOpen={searchFilterOpen}
                setSearchFilterOpen={setSearchFilterOpen}
                setSearchFilters={setSearchFilters}
              />
              {user?.caller_role.active_subscription?.package?.client?.group_banner_enabled && (
                <div className="hidden md:block aspect-[3/1] ml-10 max-w-[360px] rounded-lg">
                  <div className="flex justify-center">
                    <img
                      src={user?.caller_role.active_subscription?.package?.client?.group_banner_file_url}
                      alt="Group banner"
                      className="w-full rounded-lg"
                    />
                  </div>
                </div>
              )}
            </div>
            {searchFilters.supportQuery && searchFilters.supportQuery !== '' && (
              <>
                <div className="mb-4 w-full max-w-7xl md:px-8">
                  <ExperienceRecommendations
                    handleScheduleModalOpen={handleScheduleModalOpen}
                    recommendedExperiences={experiences?.data?.data}
                    isLoading={experiences.isLoading}
                    setSearchFilterOpen={setSearchFilterOpen}
                  />
                </div>
                {(client?.use_global_resources ||
                  (groupLevelResources?.count !== undefined && groupLevelResources?.count > 0)) && (
                  <div className="mb-4 w-full max-w-7xl md:px-8">
                    <ResourceRecommendations
                      recommendedResources={recommendedResources?.data?.data}
                      isLoading={recommendedResources.isLoading}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div
          className="
            grid grid-cols-1 mt-3 space-y-3
            md:grid-cols-8 md:space-y-0 md:mx-auto md:mt-6 md:pb-14 md:gap-6 md:max-w-7xl md:px-6 md:flex
          "
        >
          {/* We can't use space-y-3 for this div - it breaks styles for dialog */}
          <div className="flex flex-col gap-3 col-span-3 order-1 md:order-2 md:gap-6">
            {((callRequests && callRequests?.length > 0) ||
              (activeCalls && activeCalls.length > 0) ||
              (incompletePostCalls && incompletePostCalls.length > 0)) && (
              <div className="mb-4 mx-auto px-2 md:px-0 md:pr-4 ">
                <CallConnections
                  callRequests={callRequests}
                  incompletePostCalls={incompletePostCalls}
                  activeCalls={activeCalls}
                />
              </div>
            )}
            {client && client?.video_configuration && isVideo && (
              <div className="mb-4 mx-auto px-2 md:px-0 md:pr-4 ">
                <WelcomeVideo
                  videoSrc={client?.video_configuration?.video_url}
                  imageSrc={client?.video_configuration?.image_url}
                  handleCloseVideo={() => {
                    localStorage.setItem('isVideoDisplay', 'false');
                    setIsVideo(false);
                  }}
                  clientLogo={client?.comms_logo_file_url}
                />
              </div>
            )}
            {showTopSearches && (
              <div className="mb-4 mx-auto px-2 md:px-0 md:pr-4 ">
                <TopSearches
                  handleSearch={search}
                  setSearchFilters={setSearchFilters}
                  handleClose={() => {
                    localStorage.setItem('hideTopSearchClickAt', moment().toISOString());
                    setShowTopSearces(false);
                  }}
                />
              </div>
            )}
            {user?.caller_role.active_subscription?.package?.client?.group_banner_enabled && (
              <div className="block md:hidden mx-auto mb-4 aspect-[3/1] w-[360px] rounded-lg">
                <div className="flex justify-center">
                  <img
                    src={user?.caller_role.active_subscription?.package?.client?.group_banner_file_url}
                    alt="Group banner"
                    className="w-full rounded-lg"
                  />
                </div>
              </div>
            )}
            <div className="mb-4 mx-auto px-2 md:px-0 md:pr-4 ">
              <ConnectNow availableListeners={availableListeners} />
            </div>
          </div>
          <div className="flex flex-col gap-3 col-span-3 order-1 md:order-2 md:gap-6 ">
            {providerPlan && (
              <div className="mb-4 mx-auto px-2 md:px-0 md:pr-4 ">
                <CounselingAndCare
                  providerPlan={providerPlan}
                  providerPlansFetching={providerPlansFetching}
                  clientLogo={client?.comms_logo_file_url}
                />
              </div>
            )}
            {(groupLevelResources?.count || groupLevelResourcesFetching) && (
              <div className="mb-4 mx-auto px-2 md:px-0 md:pr-4 ">
                <MyPrograms
                  groupLevelResources={groupLevelResources?.data}
                  isLoading={groupLevelResourcesFetching}
                  clientLogo={client?.comms_logo_file_url}
                />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-3 col-span-3 order-1 md:order-2 md:gap-6">
            {myPlanImage && (
              <div className="mb-4 mx-auto px-2 md:px-0 md:pr-4 ">
                <MyPlan myPlanImage={myPlanImage} clientLogo={client?.comms_logo_file_url} />
              </div>
            )}
          </div>
        </div>
        <div className="h-4" />
      </div>
      {schedulingData && (
        <SchedulingModal
          listenerAvailableNow={schedulingData.isNow}
          listenerId={schedulingData.listenerId}
          open={!!schedulingData}
          onExit={() => handleCloseScheduleModal()}
        />
      )}
      {showFilterMenu && (
        <FilterMenuModal
          open={showFilterMenu}
          selected={{
            tag_ids: user?.caller_role.tag_ids ?? [],
            tag_group_ids: user?.caller_role.tag_group_ids ?? [],
          }}
          onExit={() => setShowFilterMenu(false)}
          onChange={handleFilterChange}
          fomOptions={fomHierarchy}
        />
      )}
      {showReflectionModal && currentReflection?.data && (
        <ReflectionsModal open={showReflectionModal} handleReflectionModalDismiss={handleReflectionModalDismiss} />
      )}
      {searchFilterOpen && (
        <SearchFiltersModal
          filters={searchFilters}
          setFilters={setSearchFilters}
          user={user}
          isOpen={searchFilterOpen}
          setIsOpen={setSearchFilterOpen}
          onClose={() => setSearchFilterOpen(false)}
          search={(filters) => search(undefined, filters)}
        />
      )}
    </div>
  );
};
