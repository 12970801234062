import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Spinner } from '../../components/common';
import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { SchedulingModal } from '../../components/scheduling-modal/scheduling-modal';

import PeerDetailCard from './components/peer-detail-card';
import PeerExperienceSection from './components/experiences-section';
import AboutMeSection from './components/about-me-section';
import AffiliationsSection from './components/affiliations-section';

import { peersQueryKeys, useAddFavoritePeer, usePeerDetail, useRemoveFavoritePeer } from '../../hooks/usePeers';
import { useProfilePageView } from '../../hooks/usePageView';
import { useResourceByID } from '../../hooks/useResources';
import { useUser } from '../../hooks/useUser';
import { ROUTE_PATH } from '../../routes/route-paths';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export const PeerDetailPage = () => {
  const navigate = useHistory();
  const dfMdMedia = useMediaQuery('md');

  const viewedPageMutation = useProfilePageView();
  const searchParams = new URLSearchParams(useLocation().search);
  const listenerId: number = Number(searchParams.get('listenerId'));

  const [scheduleModal, setScheduleModal] = useState(false);
  const [isNow, setIsNow] = useState(false);

  const { data: peerDetails, isLoading: peerLoading } = usePeerDetail(listenerId);
  const { data: user } = useUser();

  const userClient = user?.caller_role.active_subscription?.package?.client;
  const client = userClient?.id === peerDetails?.client_id ? userClient : undefined;
  const canTakeCalls = Boolean(peerDetails?.can_take_calls && peerDetails?.is_active);

  const addFavoritePeer = useAddFavoritePeer(peersQueryKeys.peerDetail(listenerId));
  const removeFavoritePeer = useRemoveFavoritePeer(peersQueryKeys.peerDetail(listenerId));

  const resourceID = peerDetails?.resource_id;
  const { data: resource } = useResourceByID(resourceID);

  const handleFollowing = async () => {
    const favoriteId = peerDetails?.favorite_id ?? undefined;

    if (favoriteId) {
      await removeFavoritePeer.mutateAsync(listenerId);
    } else {
      await addFavoritePeer.mutateAsync(listenerId);
    }
  };

  useEffect(
    () => {
      viewedPageMutation.mutate({ listenerRoleId: listenerId });
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (peerLoading) {
    return (
      <div className="w-full z-10 overflow-y-auto min-h-screen bg-neutral-700 bg-opacity-5">
        <DesktopMenuHeader />
        <MobileMenuHeader onBack={() => navigate.goBack()} />
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="w-full z-10 overflow-y-auto min-h-screen">
        <DesktopMenuHeader />
        <MobileMenuHeader onBack={() => navigate.goBack()} />
        <div className="">
          {peerDetails && (
            <PeerDetailCard
              peerDetails={peerDetails}
              handleFollowing={handleFollowing}
              setScheduleModal={setScheduleModal}
              setIsNow={setIsNow}
            />
          )}
          {dfMdMedia ? (
            <div className="w-full max-w-7xl mx-auto py-8 px-6 flex items-start justify-between mt-4 gap-4">
              <div className="flex flex-wrap w-full gap-4 md:max-w-2/3">
                {(client || resource) && (
                  // Super annoying to use inline styles here, but only way I could ensure that h-fit took effect. Tailwind fail.
                  <div className="flex-1" style={{ height: 'fit-content' }}>
                    <AffiliationsSection resource={resource} client={client} />
                  </div>
                )}
                {peerDetails?.listener_audio && (
                  <div className="flex-1">
                    <PeerExperienceSection
                      listenerName={peerDetails.user.display_name}
                      experiences={peerDetails?.listener_audio}
                      imageUrl={peerDetails.profile_photo_square_file_url}
                      isAvailable={peerDetails.available_now}
                      isListener={canTakeCalls}
                      listenerRoleId={listenerId}
                      clientId={peerDetails.client_id}
                    />
                  </div>
                )}
              </div>
              {peerDetails?.about_me && (
                <div className="flex-1">
                  <AboutMeSection aboutMe={peerDetails.about_me} />
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-center w-full max-w-7xl mx-auto py-8 px-6 md:flex-row-reverse md:flex-wrap md:items-start md:justify-between md:mt-4 gap-4">
              {peerDetails?.about_me && (
                <div className=" md:justify-end">
                  <AboutMeSection aboutMe={peerDetails.about_me} />
                </div>
              )}
              {(client || resource) && (
                <div className="md:self-start">
                  <AffiliationsSection resource={resource} client={client} />
                </div>
              )}
              {peerDetails?.listener_audio && (
                <div className="md:self-center">
                  <PeerExperienceSection
                    listenerName={peerDetails.user.display_name}
                    experiences={peerDetails?.listener_audio}
                    imageUrl={peerDetails.profile_photo_square_file_url}
                    isAvailable={peerDetails.available_now}
                    isListener={canTakeCalls}
                    listenerRoleId={listenerId}
                    clientId={peerDetails.client_id}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="h-6" />
      </div>
      {scheduleModal && (
        <SchedulingModal
          open={scheduleModal}
          listenerAvailableNow={isNow}
          onExit={(context) => {
            if (context === 'scheduled') {
              navigate.push(ROUTE_PATH.HOME);
            }
            setScheduleModal(false);
          }}
          listenerId={listenerId}
        />
      )}
    </>
  );
};
