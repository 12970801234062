import React, { useMemo } from 'react';
import { useExternalProviderPlan, useTelemedRedirect } from '../../../hooks/useExternalProviderPlans';
import { useUser } from '../../../hooks/useUser';
import { Spinner } from '../../../components/common';
import getPartnerResourceConfig from '../partner-resource-config';
import { ResourceDetailView } from '../resource-detail';
import { DesktopMenuHeader, MobileMenuHeader } from '../../../components/MenuHeader';
import { useHistory } from 'react-router-dom';
import { DialCare, PLUSVirtualPrimaryCare, RecuroLogo, TelemedDetailPng, Walmart } from '../../../assets';

const getTelemedProviderData = (provider?: string) => {
  if (provider === 'dialcare') {
    return {
      name: '+Telemed',
      description: 'Your benefit includes the opportunity to speak with a licensed physician.',
      longDescription: `
      Through our partnership with Dialcare, you have access to licensed clinical
      physicians to discuss health issues.
    `,
      providerText: 'THIS PLAN IS NOT INSURANCE and is not intended to replace health insurance.',
      providerImage: <img className="min-w-full" src={DialCare} alt={provider} />,
      bannerImage: TelemedDetailPng,
      ctaButtonText: 'Connect with a Physician',
    };
  } else if (provider === 'me_md') {
    return {
      name: '+Telemed',
      description: 'Your benefit includes the opportunity to speak with a licensed physician.',
      longDescription: `
      Through our partnership with MeMD, you have access to licensed clinical
      physicians to discuss health issues.
    `,
      providerText: `
      Walmart Health is comprised of independent physician practices. Walmart Health
      Virtual Care (WHVC) offers medical services via telehealth to patients
      nationwide. Telehealth services may vary by state. Telehealth services are
      provided in accordance with state law by licensed health care professionals,
      subject to the licensed professionals’ judgment. When medically necessary, WHVC
      providers may prescribe medication that can be picked up at a local pharmacy of
      the patient’s choice; WHVC does not guarantee that a prescription will be
      written. WHVC providers do not prescribe controlled substances. WHVC is not a
      pharmacy or an insurance product. Virtual Urgent Care visits are not a
      replacement for a primary care physician.
    `,
      providerImage: (
        <div className="w-80 h-48 p-8 bg-[#F6F6F6] rounded-lg shadow border border-neutral-200 grid place-items-center">
          <img className="min-w-full mb-3" src={Walmart} alt={provider} />
        </div>
      ),
      bannerImage: TelemedDetailPng,
      ctaButtonText: 'Connect with a Licensed Provider',
    };
  } else {
    return {
      name: '+Virtual Primary Care',
      description: 'Access to board-certified primary care physicians.',
      longDescription: (
        <div className="flex flex-col gap-y-4">
          Your benefit includes virtual access to top board-certified primary care physicians. You have the ability to
          choose a dedicated provider who meets you and your family’s needs and preferences.
          <div>
            COMMON USES:
            <ul>
              <li>Annual wellness exams</li>
              <li>Health risk assessment review</li>
              <li>Care plan development</li>
              <li>Chronic condition management</li>
              <li>And more! </li>
            </ul>
          </div>
          <div>ONLINE ACCESS: Click the link above to schedule a visit online.</div>
          <div>
            PHONE ACCESS: Schedule a visit by phone by calling <div>855-6RECURO (855-673-2876)</div>
          </div>
        </div>
      ),
      providerText:
        'PLUS services are provided independently by Recuro Health. © 2023 Recuro Health, Inc. All rights reserved. Recuro Health, Recuro, and the Recuro logo are trademarks of Recuro Health, Inc. and may not be used without written permission. Recuro Health does not guarantee that a physician will write a prescription, and physicians do not prescribe DEA controlled substances, non-therapeutic drugs and certain other drugs which may be harmful because of their potential for abuse. Recuro Health affiliated physicians reserve the right to deny care for potential misuse of services. Medical services provided by physicians are subject to their professional judgment. Recuro Health operates subject to state regulation and some services may not be available in certain states.',
      providerImage: (
        <div className="w-80 h-48 p-8 bg-[#F6F6F6] rounded-lg shadow border border-neutral-200 grid place-items-center">
          <img className="max-w-full mb-3" src={RecuroLogo} alt="Recuro Crisis Plan" />
        </div>
      ),
      bannerImage: PLUSVirtualPrimaryCare,
      ctaButtonText: 'Schedule Visit Online',
    };
  }
};

const Telemed: React.FunctionComponent = (): JSX.Element => {
  const navigate = useHistory();

  const { data: user, isLoading: userLoading } = useUser();
  const { data: providerPlans, isLoading: externalPlanLoading } = useExternalProviderPlan();
  const providerPlan =
    providerPlans?.find((plan) => {
      return plan.id === user?.caller_role.active_subscription?.package.external_plan_id;
    }) ?? null;

  const { data: ssoLinkUrl, isLoading: ssoLinkUrlPending } = useTelemedRedirect(user?.id || 0);
  const providerData = useMemo(() => getTelemedProviderData(providerPlan?.provider), [providerPlan]);
  const partnerResourceConfig = getPartnerResourceConfig(user, providerPlan);

  if (externalPlanLoading || userLoading) return <Spinner />;
  return (
    <div className="md:bg-neutral-700 md:bg-opacity-5 h-screen md:pb-8 flex flex-col">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <ResourceDetailView
        name={providerData.name}
        url={providerPlan?.provider === 'recuro' ? 'https://member.recurohealth.com' : ssoLinkUrl?.redirect_url}
        urlDescription={''}
        ctaButtonText={'Connect Now'}
        ctaButtonDisabled={ssoLinkUrlPending}
        imageUrl={providerData.bannerImage}
        tagNames={['Virtual Care']}
        longDescription={providerData.longDescription}
        clientLogoUrl={user?.caller_role.active_subscription?.package?.client?.comms_logo_file_url}
        partnerResourceConfig={partnerResourceConfig}
        ssoLinkUrlPending={ssoLinkUrlPending}
      />
    </div>
  );
};

export default Telemed;
